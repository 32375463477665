import { Controller } from "@hotwired/stimulus";
import autosize from "autosize";

export default class AutoSizeController extends Controller {
  connect() {
    autosize(this.element);
  }

  disconnect() {
    autosize.destroy(this.element);
  }
}
